import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setHeaderVisibility } from '../actions/questions';
import ReadSpeaker from "../components/ReadSpeaker";
import Footer from '../components/Footer.js'

class Page extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {

        var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
        if(isIE) {
            document.body.classList.add('old-browser')
        }

        this.props.actions.setHeaderVisibility(false);

    }

    handleClick() {
        const { province, settings } = this.props.quiz;
        if(settings.hasOwnProperty('has_province_page') && !settings.has_province_page) {
            this.props.history.push(`/kiezen/${province.slug}/${province.options[0].slug}`)
        } else {
            this.props.history.push('/kiezen')
        }
    }

    render() {
        const introTitle = 'Stemhulp voor de gemeenteraadsverkiezingen Breda';
        const introBody = 'Op 14, 15 en 16 maart zijn de gemeenteraadsverkiezingen. Met jouw stem kun je het verschil maken. ' +
            'Op wie stem jij? Vul de stemhulp in voor een advies!';
        const $readSpeaker = <ReadSpeaker text={introTitle + ' '+introBody} lang={'nl_nl'} voice={'Ilse'} speed={95} apikey={process.env.REACT_APP_READ_SPEAKER_KEY} />;
        return (
            <div className="pagewrap pagewrap--home">
                <div className="container">
                    {$readSpeaker}
                    <div className="c-home">
                        <div className="c-home__text">
                            <h1 className="c-main-title c-home__title" ref={(title) => this.title = title}>
                            Stemhulp voor de gemeenteraads-verkiezingen Breda
                            </h1>
                            <div className="c-home__intro" ref={(intro) => this.intro = intro}>
                                <p>
                                    {/* {introBody} */}
                                    Op <strong>14, 15 en 16 maart</strong> zijn de gemeenteraadsverkiezingen. Met jouw stem kun je het verschil maken.
                                </p>
                                <p>Op wie stem jij? Vul de stemhulp in voor een advies!</p>
                                <button className={`c-btn c-btn-home`} onClick={this.handleClick} ref={(button) => this.button = button}>
                                    <span>Start</span>
                                    <span className="c-btn-home-arrow">
                                    <svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path stroke="#FFF" stroke-width="2" d="m1 1 7 7-7 7" fill="none" fill-rule="evenodd"/></svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="c-home__loader">
                            <div className="clouds-foreground clouds-foreground--dt">
                                <img src="/media/images/home.svg"/>
                            </div>
                            <div className="clouds-foreground clouds-foreground--mob">
                                <img src="/media/images/home-mob.svg"/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            
        );
    }
}

function mapStateToProps(state) {
    return {
      index: state.index,
      quiz: state.quiz
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility  }, dispatch)
    }
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page))
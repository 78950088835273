import React, { Component } from 'react'

export default class ReadSpeaker extends Component {


    constructor(props) {
        super(props);
        this.state = {
            play: false,
            isLoaded:false,
            url:`https://tts.readspeaker.com/a/speak?key=${this.props.apikey}&command=produce&text=${this.props.text}&lang=${this.props.lang}&voice=${this.props.voice}&speed=${this.props.speed}`
        };

    }
    componentDidMount() {

        if(this.state.play) {
            this.readSpeaker.addEventListener('ended', () => this.setState({play: false}));
        }
    }

    componentWillUnmount() {
        if(this.state.play) {
            this.readSpeaker.currentTime = 0;
            this.readSpeaker.pause();
            this.readSpeaker.removeEventListener('ended', () => this.setState({play: false}));
        }
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play }, () => {
            if(!this.state.isLoaded) {
                this.readSpeaker =   new Audio(this.state.url);
                this.setState({isLoaded:true});
            }
            this.state.play ? this.readSpeaker.play() : this.readSpeaker.pause();
        });
    }
    render() {
        const { play } = this.state;
        return (
            <div className="readspeaker">
                <button onClick={this.togglePlay}>

                    {
                        play ? (
                            <svg width="75" height="75" viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg"><path d="M23 17h9.48v42.66H23V17Zm20.54 0h9.48v42.66h-9.48V17Z" fill="#D91D49" fill-rule="nonzero"/></svg>
                        ) : (
                            <svg width="75" height="75" viewBox="0 0 75 75" xmlns="http://www.w3.org/2000/svg"><g fill="#D91D49" fill-rule="nonzero"><path d="M28.251 42.099h-6.94c-4.934 0-7.311 2.352-7.311 7.05v4.703c0 4.697 2.377 7.049 7.23 7.049h7.022l9.497 9.401C42.497 75 44.874 75 49.623 75H52V28h-2.32c-4.806 0-7.183 0-11.931 4.698l-9.498 9.4v.001ZM61.992 75l-2.308-4.353.927-.65c5.667-4.398 9.282-11.056 9.282-18.503 0-7.44-3.615-14.093-9.282-18.491l-.927-.656L61.992 28l1.86 1.304C70.658 34.581 75 42.566 75 51.494c0 8.933-4.342 16.924-11.148 22.204L61.992 75Zm-4.628-8.711-2.358-4.435.212-.187c2.768-2.597 4.485-6.194 4.485-10.172 0-3.967-1.717-7.558-4.485-10.162L55 41.146l2.364-4.44c4.535 3.521 7.428 8.846 7.428 14.789 0 5.954-2.893 11.28-7.428 14.794Z"/><path d="M7.248 69C2.528 69 0 66.701 0 62.1V6.9C0 2.297 2.41 0 7.366 0H45.78C50.566 0 53 2.297 53 6.9V23h-4.82V6.9c-.024-2.324-.024-2.3-2.434-2.3H7.266c-2.451 0-2.451 0-2.451 2.3v55.2c0 2.297 0 2.297 2.439 2.297h21.668V69H7.248Zm2.404-32.206v-4.597h19.264v4.597H9.652Zm-.029-9.195v-4.605h28.904V27.6H9.623v-.001Zm.024-9.202v-4.603h33.718v4.603H9.647Z"/></g></svg>
                        )
                    }


                    <span>{ play ? 'Pauzeren' : 'Lees voor' }</span>

                </button>
            </div>
        );
    }
}

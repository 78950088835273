import React from "react";
import anime from 'animejs';

const DURATION = 1000;
const STAGGER = 30;

export default class Loader extends React.Component {

    componentDidMount() {
        
        anime({
            targets: this.all,
            delay: 1200,
            opacity: 1,
            complete: () => {
                this.tl.play()
            }
        })
        anime({
            targets: this.clouds,
            delay: 1200,
            translateX: '100%',
            easing: 'linear',
            duration: 240000,
            loop: true,
        })
        this.tl = anime({
            targets: this.el.querySelector('circle'),
            duration: DURATION,
            strokeDashoffset: [390, 0],
            easing: 'linear',
            stagger: STAGGER,
            autoplay: false,
            complete: () => {
                this.tlc.play()
            }
        });
        this.tlc = anime({
            targets: this.all,
            duration: 500,
            opacity: 0,
            autoplay: false,
            complete: () => {
                this.props.onLoadingComplete();
            }
        });
    }

    componentDidUpdate() {
        if(!this.props.isLoading) {
            this.tl.pause();
            // this.tlc.pause();
        } else {
            this.tl.pause();
            // this.tlc.pause();
        }
    }

    render() {
        return (
            <div ref={(all) => { this.all = all }} className="clouds-all">
                <div ref={(el) => { this.el = el }} className="clouds-result-spinner">
                    <svg width="132" height="132" viewBox="0 0 132 132" xmlns="http://www.w3.org/2000/svg"><circle cx="61" cy="61" r="60.5" transform="translate(5 5)" stroke-dasharray="390"  stroke-width="10" stroke="#D91D49" fill="none" fill-rule="evenodd"/></svg>
                    <h2>Jouw advies wordt geladen</h2>
                </div>
                <div ref={(clouds) => { this.clouds = clouds }} className="clouds-result-clouds">
                    <svg width="1340px" height="644px" viewBox="0 0 1340 644" version="1.1">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Group" fill="#FFFFFF">
                                <g id="Group-7">
                                    <g id="Group-3" className="clouds-1" transform="translate(121.000000, 0.000000)" opacity="0.52">
                                        <path d="M262.748004,0 C248.26054,0 235.426992,6.68507761 227.294448,16.9907628 C204.484115,8.54775736 177.557074,3.65298543 148.686825,3.65298543 C66.5696286,3.65298543 0,43.1292258 0,91.827445 C0,140.52376 66.5696286,180 148.686825,180 C203.686657,180 251.67305,162.277878 277.39534,135.952766 C287.632868,144.685268 301.810103,150.096181 317.485189,150.096181 C348.696423,150.096181 374,128.660128 374,102.218836 C374,75.7756404 348.696423,54.3395867 317.485189,54.3395867 C313.299964,54.3395867 309.23274,54.7528807 305.308259,55.4842396 C306.589139,51.4884297 307.283822,47.2450243 307.283822,42.8435387 C307.283822,19.182935 287.343576,0 262.748004,0" id="Fill-1"></path>
                                    </g>
                                    <g id="Group-6" transform="translate(0.000000, 122.000000)" className="clouds-2">
                                        <path d="M1150.53966,94.8212292 C1145.76127,94.8212292 1141.17697,95.6082618 1136.90477,97.041309 C1119.14802,63.835394 1066.7988,39.7708721 1004.9613,39.7708721 C1002.12585,39.7708721 999.318944,39.8356641 996.525362,39.9366635 C957.498942,15.3518996 902.141103,0 840.733674,0 C743.531094,0 661.478179,38.4597863 635.392058,91.1299892 C627.447095,89.0394934 618.948363,87.8884821 610.097579,87.8884821 C566.412651,87.8884821 531,115.516566 531,149.599079 C531,183.679686 566.412651,211.309675 610.097579,211.309675 C629.749707,211.309675 647.713887,205.705165 661.546686,196.451338 C699.762435,231.101735 765.737028,254 840.733674,254 C910.169798,254 971.870286,234.369921 1010.96902,203.959591 C1058.49417,202.755222 1099.81179,187.340436 1123.14809,164.682282 C1130.45365,170.879449 1140.03709,174.646915 1150.53966,174.646915 C1173.43828,174.646915 1192,156.777654 1192,134.735025 C1192,112.69049 1173.43828,94.8212292 1150.53966,94.8212292" id="Fill-4"></path>
                                        <path d="M619.539662,354.821229 C614.761266,354.821229 610.176975,355.608262 605.904773,357.041309 C588.148018,323.835394 535.798799,299.770872 473.961295,299.770872 C471.125847,299.770872 468.318944,299.835664 465.525362,299.936663 C426.498942,275.3519 371.141103,260 309.733674,260 C212.531094,260 130.478179,298.459786 104.392058,351.129989 C96.447095,349.039493 87.9483632,347.888482 79.0975791,347.888482 C35.4126513,347.888482 -5.68434189e-14,375.516566 -5.68434189e-14,409.599079 C-5.68434189e-14,443.679686 35.4126513,471.309675 79.0975791,471.309675 C98.7497071,471.309675 116.713887,465.705165 130.546686,456.451338 C168.762435,491.101735 234.737028,514 309.733674,514 C379.169798,514 440.870286,494.369921 479.96902,463.959591 C527.494172,462.755222 568.811786,447.340436 592.148093,424.682282 C599.453653,430.879449 609.037087,434.646915 619.539662,434.646915 C642.438283,434.646915 661,416.777654 661,394.735025 C661,372.69049 642.438283,354.821229 619.539662,354.821229" id="Fill-4-Copy" opacity="0.476164318"></path>
                                    </g>
                                </g>
                                <path className="clouds-3" d="M1228.748,464 C1214.26054,464 1201.42699,470.685078 1193.29445,480.990763 C1170.48412,472.547757 1143.55707,467.652985 1114.68683,467.652985 C1032.56963,467.652985 966,507.129226 966,555.827445 C966,604.52376 1032.56963,644 1114.68683,644 C1169.68666,644 1217.67305,626.277878 1243.39534,599.952766 C1253.63287,608.685268 1267.8101,614.096181 1283.48519,614.096181 C1314.69642,614.096181 1340,592.660128 1340,566.218836 C1340,539.77564 1314.69642,518.339587 1283.48519,518.339587 C1279.29996,518.339587 1275.23274,518.752881 1271.30826,519.48424 C1272.58914,515.48843 1273.28382,511.245024 1273.28382,506.843539 C1273.28382,483.182935 1253.34358,464 1228.748,464" id="Fill-1" transform="translate(1153.000000, 554.000000) scale(-1, 1) translate(-1153.000000, -554.000000) "></path>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom';
import GBLogo from './GBLogo';
import Newsletter from '../containers/newsletter';

const SHOW_NEWSLETTER_ON = [
    '/resultaat'
]
class Footer extends Component {
    render() {
        const { pathname } = this.props.location;
        return (
            <div className="c-footer" id="footer">
                
                {/* <div className="container">
                    {
                        SHOW_NEWSLETTER_ON.includes(pathname) && (
                            <Newsletter />
                        )
                    }
                </div> */}
                <div className="container">
                    <div className="c-footer__inner">
                        <div className="c-footer__col">
                            <div className="c-footer__credits">
                                <GBLogo />
                            </div>
                        </div>
                        <div className="c-footer__col">
                            <p>Deze Kieswijzer is ontwikkeld door de <a href="https://www.breda.nl/" target="_blank" rel="noopener noreferrer">Gemeente Breda</a> en <a href="https://hetnieuwekiezen.nl/" target="_blank" rel="noopener noreferrer">Het Nieuwe Kiezen</a>.</p>
                        </div>
                        <div className="c-footer__col">
                            <div className="meta">
                                <NavLink className="c-meta-link" to={`/veelgestelde-vragen`}>Veelgestelde vragen</NavLink>
                                <NavLink className="c-meta-link" to={`/privacy`}>Privacy</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		)
    }
}

export default withRouter(Footer);
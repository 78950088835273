import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { toSlug } from '../utils';
import { fetchData } from '../actions/questions'
import ReadSpeaker from "./ReadSpeaker";

class PartyDescriptions extends Component {
    state = {
        showAll: false
    }

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        // this.props.actions.fetchData();
        if(this.props.parties.length === 0) {
            this.setState({
                showAll: true
            })
        }
    }
    handleClick() {
        this.setState({
            showAll: true
        })
    }
    render() {
        const { parties, miscParties, quiz, theme } = this.props;
        const { showAll } = this.state;
        var listByProvince = quiz.list.filter(obj => {
            return obj.id === quiz.province.id
        })[0].options.filter(obj => obj.id === theme.id)[0]

        let partyOptions = [];
        listByProvince.options.forEach((row, i) => {
            row.result.forEach((party, j) => {
                partyOptions.push({
                    title: row.title,
                    party: party.id
                })
            })
        })
        let text = `${theme.title}. Politieke partijen hebben de mogelijkheid gekregen om naast hun eerste keus ook een tweede keus op te geven. Dit wordt meegenomen in je stemadvies. Bij de onderstaande antwoorden is daarom aangegeven wanneer het om de tweede keus van een partij gaat. `;
        
        if(parties.length != 0) {
            parties.forEach((partyElement) => {
                text += ` Toelichting ${partyElement.title} ${partyElement.score == 2 ? ' (tweede keus)' :''}.  ${theme.subTitle} ${partyOptions.filter((row, i) => row.party === partyElement.id)[0].title} ${partyElement.desc}.`;
            });
        } else {
            text = `${theme.title}. Politieke partijen hebben de mogelijkheid gekregen om naast hun eerste keus ook een tweede keus op te geven. Dit wordt meegenomen in je stemadvies. Bij de onderstaande antwoorden is daarom aangegeven wanneer het om de tweede keus van een partij gaat. Geen van de partijen heeft uw smaak gekozen. Hieronder een overzicht van de gemaakte keuzes met een toelichting. `;
            
        }
        const $readSpeaker = <ReadSpeaker text={text} lang={'nl_nl'} voice={'Ilse'} speed={95} apikey={process.env.REACT_APP_READ_SPEAKER_KEY} />;
        return (
            <div>
                {$readSpeaker}
                {
                    parties.map((res, j) => (
                        <div className={`c-result-party c-result-party--selected t-${toSlug(res.title)}`} key={`party-${res.id}`}>
                            <h2>Toelichting {res.title} {res.score == 2 ? ' (tweede keus)' :''}</h2>
                            <span className="c-result-description-title">
                                {theme.subTitle+' '}
                                {partyOptions.filter((row, i) => row.party === res.id)[0].title}
                            </span>
                            <div className="c-result-description-text">{res.desc}</div>
                        </div>
                    ))

                }
                {
                    !showAll && miscParties.length > 0 && (
                        <button className={`c-result-all-parties`} onClick={this.handleClick}>Alle partijen</button>
                    )
                }

{
                parties.length === 0 && (
                        <div className="c-intro c-intro--wide">
                            <p>Geen van de partijen heeft uw smaak gekozen. Hieronder een overzicht van de gemaakte keuzes met een toelichting.</p>
                        </div>
                    )
                }

                {
                    showAll &&
                    miscParties.map((miscRes) => {
                        return miscRes.result.map((partyRes, x) => {
                            return (
                                <div className={`c-result-party t-${toSlug(partyRes.title)}`} key={`party-${partyRes.id}`}>
                                    <h2>Toelichting {partyRes.title} {partyRes.score == 2 ? ' (tweede keus)' :''}</h2>
                                    <span className="c-result-description-title">
                                        {theme.subTitle+' '}
                                        {partyOptions.filter((row, i) => row.party === partyRes.id)[0].title}
                                    </span>
                                    <div className="c-result-description-text">{partyRes.desc}</div>
                                </div>
                            )
                        })
                    })
                }


            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
      quiz: state.quiz
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ fetchData }, dispatch)
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(PartyDescriptions)
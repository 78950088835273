import { randomizeArray } from '../utils';

const initialState = {
    list: [],
    province: null,
    themes: [],
    answeredQuestions: 0,
    testFinished: false,
    forcedProvince: false,
    settings: [],
    user: null,
    headerVisible: false,
    loading: false
}

export function quiz(state = initialState, action) {
    switch (action.type) {

        case 'SET_HEADER_LOADING':
            return {
                ...state,
                loading: action.payload
            }
          
        case 'SET_HEADER_VISIBILITY':
            return {
                ...state,
                headerVisible: action.payload
            }

        case 'SET_USER_PROPS':
            return {
                ...state,
                user: action.payload
            }

    

        case 'RESET_TEST':
            return {
                ...state,
                province: null,
                themes: [],
                answeredQuestions: 0,
                testFinished: false
            }

        case 'FINISH_TEST':
            return {
                ...state,
                testFinished: true
            }

        case 'FETCH_DATA':
            const settings = {};
            action.payload.settings.forEach(r => {
                settings[r.setting] = r.value
            })

            return {
                ...state,
                list: action.payload.questions,
                settings: settings
            }

        case 'SET_PROVINCE':
            return {
                // ...state,
                // province: action.payload,
                // forcedProvince: action.forced
                ...state,
                province: {
                    ...action.payload,
                    options: action.payload.options.map(o => {
                        return {
                            ...o,
                            options: o.options.map(p => {
                                return {
                                    ...p,
                                    result: randomizeArray(p.result)
                                }
                            })
                        }
                    })
                },
                forcedProvince: action.forced
            }
        
        case 'SET_THEMES':
            return {
                ...state,
                themes: action.payload
            }

        case 'ANSWER_QUESTION':
            return {
                ...state,
                loading: true,
                province: {
                    ...state.province,
                    options: state.province.options.map((item, i) => {
                        if(item.id === action.payload.question.id) {
                            item.storedAnswerId = action.payload.answer.id // Store the answer ID for navigation back and forth trough questions
                            item.answer = action.payload.answer
                        }
                        return item;
                    })
                },                
            }
            
        case '@@router/LOCATION_CHANGE':
            window.scrollTo(0,0);
            

            const { pathname } = action.payload.location
            const urlParts = pathname.split('/');

            
            if(urlParts.indexOf('kiezen') !== -1 && urlParts.length === 4) {
                // window.dataLayer.push({
                //     'event': 'vpv',
                //     'pagepath': pathname                             // bijvoorbeeld /kiezen/zuid-holland/vervoer
                // });
            }
          
            if(pathname === '/' && !state.forcedProvince) {
                return {
                    ...state,
                    province: null,
                    themes: [],
                    answeredQuestions: 0,
                    testFinished: false,
                    forcedProvince: false
                }
            }

            if(urlParts.length === 4) {
                //Clear current question
                const slug = urlParts[3];

                if(state.province) {
                    state = {
                        ...state,
                        province: {
                            ...state.province,
                            options: state.province.options.map((item, i) => {
                                if(item.slug === slug) {
                                    delete item.answer;
                                }
                                return item;
                            })
                        },
                        answeredQuestions: state.province.options.filter((item, i) => {
                            return item.answer;
                        }).length
                    }
                }
            }

            return {
                ...state
            }
                    

        default:
            return state

      }
}

import React, { Component } from "react";
import anime from 'animejs';
import { withRouter } from 'react-router-dom';
const DURATION = 500;
const NOT_SHOW_CLOUDS = [
    
]
class Clouds extends Component {

    componentDidMount() {
        let mql = window.matchMedia('(min-width: 1024px)');
        if(mql.matches) {
            this.tl = anime({
                targets: this.el.getElementById('clouds'),
                duration: DURATION,
                easing: 'linear',
                complete: () => {
                    this.props.onLoadingComplete();
                }
            });
    
            anime({
                targets: this.el,
                translateX: '100%',
                easing: 'linear',
                duration: 240000,
                loop: true,
            })
        }
    }

    componentDidUpdate() {
        let mql = window.matchMedia('(min-width: 1024px)');
        if(mql.matches) {
            if(!this.props.isLoading) {
                this.tl.pause();
            } else {
                this.tl.play();
            }
        }
    }

    render() {
        const { pathname } = this.props.location;
        return (
            
            <div>
                {!
                    NOT_SHOW_CLOUDS.includes(pathname) && (
                        <div className="clouds-wrapper">
                        <svg ref={(el) => { this.el = el }} id="clouds" width="548px" height="158px" viewBox="0 0 548 158" version="1.1">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Group" fill="#FFFFFF" fill-rule="nonzero">
                                    <g id="Group-3" opacity="0.52">
                                        <path d="M138.053,0 C130.441,0 123.698,3.51 119.425,8.921 C107.44,4.488 93.292,1.918 78.123,1.918 C34.977,1.918 0,22.645 0,48.214 C0,73.782 34.977,94.509 78.123,94.509 C107.021,94.509 132.234,85.204 145.749,71.382 C151.128,75.967 158.577,78.808 166.813,78.808 C183.212,78.808 196.507,67.553 196.507,53.67 C196.507,39.786 183.212,28.531 166.813,28.531 C164.614,28.531 162.477,28.748 160.415,29.132 C161.088,27.034 161.453,24.806 161.453,22.495 C161.453,10.072 150.976,0 138.053,0" id="Fill-1"></path>
                                    </g>
                                    <g id="Group-6" transform="translate(199.887300, 23.824700)">
                                        <path d="M325.562,49.758 C323.051,49.758 320.642,50.171 318.397,50.923 C309.066,33.498 281.557,20.87 249.062,20.87 C247.572,20.87 246.097,20.904 244.629,20.957 C224.121,8.056 195.031,0 162.762,0 C111.683,0 68.565,20.182 54.857,47.821 C50.682,46.724 46.216,46.12 41.565,46.12 C18.609,46.12 0,60.618 0,78.503 C0,96.387 18.609,110.886 41.565,110.886 C51.892,110.886 61.332,107.945 68.601,103.089 C88.683,121.272 123.352,133.288 162.762,133.288 C199.25,133.288 231.673,122.987 252.219,107.029 C277.193,106.397 298.905,98.308 311.168,86.418 C315.007,89.67 320.043,91.647 325.562,91.647 C337.595,91.647 347.349,82.27 347.349,70.703 C347.349,59.135 337.595,49.758 325.562,49.758" id="Fill-4"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        </div>
                    )   
                }
            </div>
        )
    }
}
export default withRouter(Clouds);
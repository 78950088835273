import React, { Component } from 'react'

export default class Button extends Component {

    handleClick() {
        if(typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
        return;
    }

    render() {
        const {title} = this.props;
        return <button onClick={this.handleClick.bind(this)} className={`c-btn ${this.props.subClass}`}><span>{title}</span><svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path stroke="#FFF" stroke-width="2" d="m1 1 7 7-7 7" fill="none" fill-rule="evenodd"/></svg></button>
    }
}
export function toSlug(str = '') {
    return str.toLowerCase().replace(/ /g, '-')
}

export function randomizeArray(arr) {

    for(let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = arr[i]
        arr[i] = arr[j]
        arr[j] = temp
    }

    return arr;
}
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setThemes, setHeaderLoading } from '../actions/questions'
// import { TimelineMax, TweenMax } from 'gsap';
import Button from '../components/Button'
import ReadSpeaker from "../components/ReadSpeaker";
import CheckAnimation from "../lottie/Check.js"

const MAX_SELECTIONS = 3;

class Themes extends Component {

	componentDidMount() {
		this.props.actions.setThemes([]);
		this.props.actions.setHeaderLoading(false);
	}

	change(item, e) {
		let { themes } = this.props.quiz;
		let currState = themes;
		let entryIndex = currState.indexOf(item.id);
		if(entryIndex !== -1) {
			//remove
			currState.splice(entryIndex, 1);
		} else {
			//add
			currState.push(item.id)
		}
		//Update redux
		this.props.actions.setThemes(currState);
	}

	submit() {

		const { settings } = this.props.quiz;
		this.props.actions.setHeaderLoading(true);
		if(settings.has_about_page) {
			this.props.history.push(`/uw-gegevens`);
		} else {
			this.props.history.push(`/resultaat`);
		}

	}

	render() {
		const { list, province } = this.props.quiz;

		let { themes } = this.props.quiz;

		const $submit = themes.length >= MAX_SELECTIONS ? <Button subClass={`c-btn--fixed`} title={`Volgende`} onClick={this.submit.bind(this)} /> : ''
		const $themes =
			list.filter((item, i) => province.id === item.id)[0].options
			.map((item, i) => {

				let disabled = false;
				if(themes.length === MAX_SELECTIONS && !themes.includes(item.id)) {
					disabled = true;
				}
				const isAnswer = themes.includes(item.id);
				return(
					<div className={`c-group__item c-checkbox`} key={`theme-${item.id}`}>
						<input disabled={disabled} onChange={this.change.bind(this, item)} id={`theme-${item.id}`} type="checkbox" />
						<label htmlFor={`theme-${item.id}`}>
							<span className="toggle">
								<CheckAnimation isAnswered={isAnswer} className={`checker-${item.id}`} ref={this.checkBox} />
							</span>
							{item.title}
						</label>
					</div>
				)
			})

		const text = `Deze 3 onderwerpen vind ik het belangrijkst.`;
		const $readSpeaker = <ReadSpeaker text={text} lang={'nl_nl'} voice={'Ilse'} speed={95} apikey={process.env.REACT_APP_READ_SPEAKER_KEY} />;
		return (

				<div className="container">
				{$readSpeaker}
				<div className="page">
					<div className="select-theme-container">
						<h1 ref={(title) => this.title = title} className={`c-main-title c-main-title--alt`}>Deze 3 onderwerpen vind ik het belangrijkst</h1>
						<div ref={(items) => this.items = items} className={`c-group`}>
							{$themes}
						</div>
						<div className="c-btn-holder" ref={(pag) => this.pagination = pag }>
							<div className={`c-pagination`}>
								{$submit}
								{
									province.slug === 'utrecht' &&
									(
										<div>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}>*De PVV doet niet mee aan de Kieswijzer voor de provincie Utrecht</span>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}><br/>*Forum voor Democratie doet niet mee aan de Kieswijzer</span>
										</div>
									)
								}
								{
									province.slug === 'noord-holland' &&
									(
										<div>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}>*De Ouderenpartij NH doet niet mee aan de Kieswijzer voor de provincie Noord-Holland</span>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}><br/>*Forum voor Democratie doet niet mee aan de Kieswijzer</span>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
  return {
    quiz: state.quiz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setThemes, setHeaderLoading }, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Themes))
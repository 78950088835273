import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios';
import Footer from '../components/Footer.js'

import Chart from '../containers/chart'
import Popup from '../components/Popup'
import PartyDescriptions from '../components/PartyDescriptions'
import Loader from '../components/Loader'

import { finishTest, setHeaderVisibility, setHeaderLoading } from '../actions/questions'

import { toSlug } from '../utils';
import ReadSpeaker from "../components/ReadSpeaker";

import Clouds from '../components/Clouds';

const API_ENDPOINT_POST = process.env.REACT_APP_API_ENDPONT + '/api/result';
const MAX_PARTIES = 5;



class Result extends Component {

	constructor() {
		super();
		this.state = {
			isLoading: true,
			popup: {
				open: false,
				content: {}
			},
			allThemes: false,
			noAgreement: false,
		}
		this.showAll = this.showAll.bind(this)
		this.hideAll = this.hideAll.bind(this)
	}

	componentDidMount() {
		this.props.actions.setHeaderVisibility(false);
		this.props.actions.setHeaderLoading(false);
		if(!this.props.quiz.province) {
			this.props.history.push('/');
			return;
		}

		document.body.classList.add('c-result-body')

		const { testFinished } = this.props.quiz;

		if(!testFinished) {

			const ANSWERS = this.props.quiz.province.options.map(o => {
				return o.storedAnswerId;
			})

			//Build object for API endpoint
			axios.post(API_ENDPOINT_POST, {
				'info': {
					...this.props.quiz.user
				},
				'province': {
					'id': this.props.quiz.province.id,
					'name': this.props.quiz.province.title
				},
				// 'answers': this.props.quiz.province.options,
				'answers': ANSWERS,
				'selectedThemes': this.props.quiz.themes
			}, {
				headers: {'X-API-KEY': process.env.REACT_APP_API_KEY}
			});
			this.props.actions.finishTest();
		}
	}

	componentWillUnmount() {
		document.body.classList.remove('c-result-body')
	}

	closePopup() {
		this.setState({
			popup: {
				open: false,
				content: {}
			}
		})
	}

	openPopup(content, e) {

		this.setState({
			popup: {
				open: true,
				content: content
			}
		})
	}

	getThemes(remaining=false) {
		const { province, themes  } = this.props.quiz
		let $list;
		return province.options.map((item, i) => {
			if(!item.answer) return false;

			const { id, title, answer } = item;
			if(themes.includes(id) === remaining) return false;


			const { result } = answer;
			const parties = result;


			const miscParties = item.options.filter((miscRes, j) => {
				return miscRes.id !== answer.id && miscRes.result.length
			})

			if(result.length >= MAX_PARTIES) {
				$list = <li className={''}>{result.length} partijen</li>
			} else if(result.length === 0) {
				$list = <li className={`c-result__star-wrapper`}><span className="c-result__star">*</span></li>

			} else {
				$list = result.map((res, j) => (
					<li key={res.id} className={`t-${toSlug(res.title)}`}>
						{res.title}
					</li>
				))
			}

			return (
				<div
					onClick={this.openPopup.bind(this, {
						title: title,
						body: <PartyDescriptions theme={item} parties={parties} miscParties={miscParties} />
					})}
					className={`c-result__item`}
					key={`res-${id}`}
				>
					<span>{title}</span>
					<ul>
						{$list}
					</ul>
				</div>
			)
		})
	}



	showAll(e) {

		this.setState({
			allThemes: true
		})
	}

	hideAll(e) {
		this.setState({
			allThemes: false
		})
	}

	loadingComplete() {

		this.setState({
			isLoading: false
		}, () => {
			this.props.actions.setHeaderVisibility(true);
		})
	}


	getSpokenPartyText(province,themes)
	{
		let parties;
		let totalPoints = 0;
		let pointsPerParty = [];
		let build = [];
		let spokenText = '';
		totalPoints += (province.options.length * 6);
		province.options.forEach((question, i) => {
			if(question.answer) {
				parties = question.answer.result;
				parties.forEach( ( p ) => {
					if(!pointsPerParty[p.title]) {
						pointsPerParty[p.title] = 0;
					}

                    pointsPerParty[p.title] += p.score;
				});
			}
		})
		Object.keys( pointsPerParty).forEach((p, i) => {
			build.push({
				'title': p,
				'qty': pointsPerParty[p],
				'percentage': (pointsPerParty[p] / totalPoints) * 100
			})
		})
		build.sort((a, b) => b.qty - a.qty);
		build.forEach((element,i)=> {
			spokenText += `${element.title}. `;
		});
		return spokenText;
	}

	render() {
		const { province ,themes} = this.props.quiz
		if(!province) {
			this.props.history.push(`/`)
			return false;
		}

		const subjectTitlesArray = this.props.quiz.themes.map((element, i) => {
			const currentTitle = this.props.quiz.province.options.find(item=> item.id === element).title;
			if(typeof currentTitle !== 'undefined') {
				return currentTitle+'.';
			}
			return '';
		});
		const subjectTitles = subjectTitlesArray.join(' ');
		const spokenParties =  this.getSpokenPartyText(province,themes);
		const $listChosen = this.getThemes();
		const listRemaining = this.getThemes(true);

		const { popup, allThemes } = this.state;
		const $popup = popup.open ? <Popup content={popup} close={this.closePopup.bind(this)} /> : false;
		const $showAll = !allThemes ? <button className={`c-btn-add`} onClick={this.showAll}>Toon alle onderwerpen</button> : false
		const $hideAll = allThemes ? <button className={`c-btn-add c-btn-less`} onClick={this.hideAll}>Verberg overige onderwerpen</button> : false

		let $listRemaining;
		if(allThemes) {
			$listRemaining = (
				<div className={`c-result c-result--remaining`}>
					{listRemaining}
				</div>
			)
		}
		if(this.state.isLoading) {
			return (
				<div className="page clouds-result-loading">
					<Loader isLoading={true} loop={2} onLoadingComplete={this.loadingComplete.bind(this)} />
				</div>
			)
		}


		const text  = `Uitslag. Ontdek met welke partijen je de meeste raakvlakken hebt. Klik op een onderwerp voor meer verdieping. ${subjectTitles}
		Omdat je op 14, 15 en 16 maart maar op 1 partij kunt stemmen vind je hieronder de lijst met partijen die het meest overeenkomen met jouw standpunten. ${spokenParties}`
		const $readSpeaker = <ReadSpeaker text={text} lang={'nl_nl'} voice={'Ilse'} speed={95} apikey={process.env.REACT_APP_READ_SPEAKER_KEY} />;
		return (
			<div>
				<div className="clouds-container">
					<Clouds/>
				</div>
					<div className="container">
						{$readSpeaker}
						<div className="page">
							<div className={`o-content`}>
								<h1 className={`c-main-title c-main-title--alt c-main-title--result`}>Uitslag</h1>
								<h2 className="c-title-sub c-title-sub--result">Ontdek met welke partijen je de meeste raakvlakken hebt.</h2>
								<div className={`c-intro c-intro--result`}>Klik op een onderwerp voor meer verdieping</div>
								<div className={`c-result`}>
									{$listChosen}
								</div>

								{ $listRemaining }
								<div className="c-result-flex-buttons">
									{ $showAll }
									{ $hideAll }
								</div>

								<div className={`o-section`}>
									<h2 className={`c-title-sub c-title-sub--result-alt`}>Omdat je op 14, 15 en 16 maart maar op 1 partij kunt stemmen …</h2>
									<p className="c-paragraph--result">… vind je hieronder de lijst met partijen die het meest overeenkomen met jouw standpunten.</p>
									<Chart />
								</div>
								<div className={`o-section`}>
									<p>Als je de uitgebreidere partijstandpunten van de deelnemende politieke partijen wil zien, ga dan naar de <a href="https://www.breda.nl/kandidatenlijsten-en-verkiezingsprogrammas" target="_blank">Kandidatenlijsten en verkiezingsprogramma’s</a>.</p>
								</div>
							</div>
							{$popup}
						</div>
					</div>
				<Footer/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		quiz: state.quiz
	}
}

function mapDispatchToProps(dispatch) {
	return {
	  actions: bindActionCreators({ finishTest, setHeaderLoading, setHeaderVisibility }, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Result)

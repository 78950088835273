import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setHeaderVisibility } from '../actions/questions'
import Footer from '../components/Footer.js'
class Disclaimer extends Component {
    
    componentDidMount() {
        this.props.actions.setHeaderVisibility(true);
    }

    render() {
        return (
            <div>
                <div className="page page-privacy">
                    <div className="container">
                        <div className={`o-content o-content--textpage`}>
                            <h1 className="c-main-title">Disclaimer en privacy statement</h1>

                            
<p>De Stemhulp Breda is ontwikkeld op initiatief van de gemeente Breda. Voor meer informatie over de organisatie verwijzen we je naar de website <a href="https://www.breda.nl/" target="_blank">www.breda.nl</a>.</p> 
<p>De voorwaarden van deze disclaimer en dit privacy statement zijn van toepassing op www.breda.nl/stemhulp. Door deze website te bezoeken en/of de op of via deze website aangeboden informatie te gebruiken, verklaar je dat je akkoord gaat met de toepasselijkheid van deze disclaimer en dit privacy statement.</p>
<h2>Disclaimer</h2>
<h3>Aansprakelijkheid</h3>
<p>Wij zullen er alles aan doen om andermans auteursrechten te respecteren en na te leven. Mocht onverhoopt toch gebruik zijn gemaakt van andere bronnen (zonder bronvermelding) neem dan contact op via: <a href="mailto:raadsgriffie@breda.nl">raadsgriffie@breda.nl</a>. De informatie in deze stemhulp is ontleend aan bronnen die openbaar inzichtelijk zijn en betrouwbaar mogen worden geacht, maar voor de juistheid en volledigheid daarvan kan niet worden ingestaan. De politieke partijen die in de stemhulp genoemd worden zijn op geen enkele manier aansprakelijk voor de inhoud ervan.</p>
<h3>Gebruik van de website</h3>
<p>Aan de informatie in deze stemhulp kunnen geen rechten worden ontleend. De initiatiefnemers aanvaarden geen enkele aansprakelijkheid voor de inhoud van sites die niet door de initiatiefnemers worden onderhouden en waarnaar wordt verwezen of die verwijzen naar de site van de initiatiefnemers. Hoewel de initiatiefnemers alles in het werk stellen om misbruik te voorkomen, zijn de initiatiefnemers niet aansprakelijk voor informatie en/of berichten die door gebruikers van de website via het internet verzonden worden.</p>
<h2>Privacy statement</h2>
<p>De initiatiefnemers van de stemhulp Breda hechten waarde aan jouw privacy. Wij houden ons dan ook aan de relevante wet- en regelgeving over privacy, waaronder de Algemene Verordening Gegevensbescherming.</p>
<p>Dit betekent dat wij:</p>
<ul>
<li>In deze privacyverklaring onze doeleinden duidelijk vastleggen, voordat wij jouw persoonlijke gegevens verwerken;</li>
<li>Zo min mogelijk persoonlijke gegevens opslaan en enkel de gegevens opslaan die nodig zijn voor onze doeleinden;</li>
<li>Expliciet toestemming vragen voor de verwerking van jouw persoonlijke gegevens, mocht toestemming verplicht zijn;</li>
<li>Benodigde beveiligingsmaatregelen treffen om jouw persoonlijke gegevens te beschermen. Wij leggen deze verplichtingen ook op aan partijen die persoonsgegevens voor ons verwerken;</li>
<li>Jouw rechten respecteren, zoals het recht op inzage, correctie of verwijdering van jouw bij ons verwerkte persoonsgegevens.</li>
</ul>
<p>In deze privacyverklaring leggen we uit wat we doen met informatie over jou. Als je hier vragen over hebt neem dan contact op met: <a href="mailto:raadsgriffie@breda.nl">raadsgriffie@breda.nl</a>.</p>
<h3>Welke gegevens worden er verzameld?</h3>
<p>Wanneer je de stemhulp start, wordt deze lokaal geladen op jouw eigen apparaat. Je antwoorden op de onderwerpen en de uitslag na het voltooien van de stemhulp worden geanonimiseerd geregistreerd. Dit geldt ook voor de door jou ingevulde gegevens bij de ‘Over jou’-pagina, waarbij gevraagd wordt naar je geslacht, leeftijd, wijk/dorp en opleidingsniveau. Antwoorden kunnen dus niet herleid worden tot een persoon. Dankzij de verzameling geanonimiseerde gegevens krijgen we een beeld van de politieke voorkeuren van inwoners van Breda.</p> 
<h3>Cookies en Google Analytics</h3>
<p>Wij maken geen gebruik van cookies of Google Analytics.</p>
<h3>Verstrekking aan andere bedrijven of instellingen</h3>
<p>Met uitzondering van de partijen die nodig zijn om de hierboven genoemde diensten te leveren, geven wij jouw persoonsgegevens onder geen voorwaarde aan andere bedrijven of instellingen, behalve als wij dat wettelijk verplicht zijn.</p>
<h3>Beveiliging</h3>
<p>Beveiliging van persoonsgegevens vinden wij belangrijk. Wij zorgen dat jouw gegevens goed beveiligd zijn.</p>
<h3>Jouw rechten</h3>
<p>Als je vragen hebt of wilt weten welke persoonsgegevens wij van jou hebben bewaard, kun je altijd contact met ons opnemen via: <a href="mailto:raadsgriffie@breda.nl">raadsgriffie@breda.nl</a>.</p>
<p>Je hebt de volgende rechten:</p>
<ul>
<li>uitleg krijgen over welke persoonsgegevens we hebben en wat we daarmee doen</li>
<li>inzage in de precieze persoonsgegevens die we hebben</li>
<li>het laten corrigeren van fouten</li>
<li>het laten verwijderen van verouderde persoonsgegevens</li>
<li>intrekken van toestemming</li>
<li>een bepaalde verwerking beperken</li>
<li>bezwaar maken tegen een bepaald gebruik</li>
</ul>
<p>We zullen je vragen duidelijk aan te geven wie je bent, zodat we er zeker van kunnen zijn dat we jouw gegevens aanpassen of verwijderen, en niet die van een ander.</p>
<p>Wij zullen in principe binnen 30 dagen aan jouw verzoek voldoen. Deze termijn kan echter worden verlengd om redenen die verband houden met de specifieke rechten van betrokkenen of de complexiteit van het verzoek. Als wij deze termijn verlengen, zullen wij je daarvan tijdig op de hoogte stellen.</p>
<h3>Klacht indienen</h3>
<p>Als je een klacht in wil dienen over het gebruik van jouw persoonsgegevens kan je een bericht sturen naar: <a href="mailto:raadsgriffie@breda.nl">raadsgriffie@breda.nl</a>. Wij pakken een klacht intern op, en communiceren hierover met jou. Als je vindt dat wij je niet op de juiste manier helpen, dan heb je het recht om een klacht in te dienen bij de toezichthouder: de Autoriteit Persoonsgegevens.</p>

                        </div>

                    </div>
                    
                </div>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      quiz: state.quiz
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility }, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer)
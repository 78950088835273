import React, { Component } from 'react';
import { Route } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SlideRouter, { initSlideRouter } from 'react-slide-animation-router'
import Index from '../pages/index';
import Quiz from '../pages/quiz';
import Result from '../pages/result';
import Faq from '../pages/faq';
import Privacy from '../pages/privacy';
import UserFields from '../pages/userFields';
import Themes from '../containers/themes';
import Header from '../components/Header';
import Clouds from '../components/Clouds';
import { fetchData } from '../actions/questions'

import { history } from '../store'

class Page extends Component {

  componentDidMount() {
    this.props.actions.fetchData();

    initSlideRouter({
      history,
      routeAnimationDuration: 1150,
      isRememberPosition: false,
      classNames: 'route-transition'
    })
    
  }

  render() {
    
    const { list } = this.props.quiz;
  
    if(!list.length) {
      return false;
    }

    
    
    const renderRouter = () => {
      
      return (
        <SlideRouter>
          <Route exact path="/" component={Index} />
          <Route exact path="/kiezen" component={Quiz} />
          <Route path="/kiezen/:province/:question" component={Quiz} />
          <Route path="/kiezen/:province" component={Quiz} />
          <Route exact path="/resultaat" component={Result} />
          <Route exact path="/veelgestelde-vragen" component={Faq} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/uw-gegevens" component={UserFields} />
          <Route exact path="/themas" component={Themes} />
        </SlideRouter>
      )
    }

    return (
      
      <ConnectedRouter history={history}>
        <div>
          <Header visible={this.props.quiz.headerVisible} />
          <main className="main-content">
            <Clouds/>
            <Route render={renderRouter}/>
          </main>
          
        </div>
      </ConnectedRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    quiz: state.quiz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchData }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)

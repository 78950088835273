import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FaqItem from '../components/FaqItem';
import { setHeaderVisibility } from '../actions/questions'
import Footer from '../components/Footer.js'
class Faq extends Component {
    
    componentDidMount() {
        this.props.actions.setHeaderVisibility(true);
    }

    render() {
        return (
            <div>
                <div className="page">
                    <div className="container">
                        <div className={`o-content o-content--textpage`}>
                            <h1 className="c-main-title">
                                <span>Veelgestelde</span>
                                <span> vragen</span>
                            </h1>
                            <p><span className={`c-question__description`}>Deze stemhulp is ontwikkeld voor de Gemeenteraadsverkiezingen van 2022 die op 15, 16 en 17 maart worden gehouden.</span></p>

                            
                            <div className={`c-faq`}>
                                <FaqItem question={`Door wie is de stemhulp ontwikkeld?`}>
                                    <p>Deze stemhulp is ontwikkeld door de gemeente Breda. Voor de totstandkoming van deze stemhulp is samengewerkt met Het Nieuwe Kiezen. Deze organisatie ontwikkelde het innovatieve concept voor stemhulp waarbij geen gebruik wordt gemaakt van stellingen (eens/oneens), maar van uiteenlopende oplossingsrichtingen per onderwerp.</p>
                                </FaqItem>

                                <FaqItem question={`Wat is het doel van deze stemhulp? `}>
                                    <p>Het doel van deze stemhulp is drieledig:</p>
                                    <ul>
                                        <li>burgers helpen bij het kiezen</li>
                                        <li>politieke partijen moeten zich uitspreken over onderwerpen</li>
                                        <li>de stemhulp leert ons als onderzoekstool veel over hoe Bredanaars denken  over voor hen belangrijke onderwerpen en politiek.</li>
                                    </ul>
                                </FaqItem>
                                
                                <FaqItem question={`Wat maakt deze stemhulp anders dan traditionele stemhulpen?`}>
                                    <p>Een traditionele stemhulp bestaat uit een reeks stellingen waarmee je het eens of oneens bent. Eens of oneens levert echter een te zwart-wit beeld op. In veel gevallen doet dat geen recht aan wat je echt vindt. In deze vernieuwde stemhulp kies je bij ieder thema uit 5 uiteenlopende oplossingen voor een probleem. Daarmee is de kans een stuk groter dat jouw smaak er wel tussen zit. Op deze wijze krijg je een genuanceerder beeld van welke partij het beste bij jou past en stem je nóg wijzer. Daarbij krijg je bij de uitslag niet alleen te zien welke partij in het algemeen het best bij je past, maar kun je ook per thema zien hoe de verschillende partijen zich tot elkaar verhouden.</p>
                                </FaqItem>
                                
                                <FaqItem question={`Waarom kan ik maar voor één van de vijf antwoordopties kiezen?`}>
                                    <p>Het concept is zo ingericht dat je steeds 1 antwoordoptie moet aanvinken. De vraag is namelijk wat de béste oplossing is. Oftewel waar de prioriteit moet liggen. Dit sluit andere oplossingen dus niet uit, maar geeft wel aan waar de (politieke) focus moet liggen. Het doel daarbij is om te laten zien dat er nuances zijn in de praktijk. Binaire (eens/oneens) stellingen doen namelijk geen recht aan de moeilijkheid van de realiteit. De vraag is dus niet óf we moeten investeren in bijvoorbeeld cultuur, maar hoé we dat moeten doen. Politieke partijen hebben de mogelijkheid gekregen om naast hun eerste keus ook een tweede keus op te geven. De consequentie is dat de punten die normaal worden toegekend aan hun eerste keus, worden verdeeld over de eerste en tweede keus. Zo wordt het stemadvies verbeterd.</p>
                                </FaqItem>
                                
                                <FaqItem question={`Waarom worden sommige opties door geen enkele partij gerepresenteerd?`}>
                                    <p>De inhoud van deze stemhulp is onafhankelijk van politieke partijen samengesteld. Voor de inhoud is niet alleen gekeken naar partijprogramma’s, maar ook naar relevante en actuele onderzoeken en nieuwsartikelen. Pas nadat de inhoud volledig was samengesteld is deze voorgelegd aan politieke partijen. Aan hen is vervolgens gevraagd om aan te geven welke optie zij vertegenwoordigen en wat de toelichting hierop is. Het kan dus voorkomen dat je voor een antwoordoptie hebt gekozen dat wel relevant en actueel is, maar niet door een politieke partij wordt vertegenwoordigd. De thema’s zijn bepaald met behulp van een burgerpanel.</p>
                                </FaqItem>
                            
                            </div>
                        </div>
                        
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      quiz: state.quiz
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility }, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Faq)